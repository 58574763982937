.show-time {
}

.show-btn {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 16px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: black;
  border: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}

.show-now {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.show-time .cat {
    width: 200px;
    cursor: pointer;
}

.show-time .lyric-display {
    padding: 4px;
    background-color: rgba(50, 50, 50, 0.8);
    color: white;
    font-size: 30px;
}

.show-time .lyric-translated {
    font-size: 20px;
}

.show-time .lyric-display b {
    font-weight: bold;
    background-color: white;
    color: black;
}

.background {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -10;
    overflow: hidden;
}

.background-text {
    position: fixed;
    z-index: -1;
    top: 10%;
    left: 10%;
    color: gray;
    font-size: 5em;
    font-weight: bold;
}

.backgroundText1 {
    top: 20%;
    /* background-color: rgba(51, 102, 204, 1);
    color: rgba(255, 102, 0, 1); */
    text-transform: uppercase;
}

.stage2 {
    background-color: rgba(51, 204, 51, 0.8);
}
.backgroundText2 {
    background-color: rgba(51, 204, 51, 1);
    color: rgba(153, 51, 255, 1);
    font-size: 7em;
}

.stage3 {
    background-color: rgba(0, 153, 51, 0.8);
}
.backgroundText3 {
    background-color: rgba(0, 153, 51, 1);
    color: rgba(255, 51, 153, 1);
    right: 10%;
}

.stage4 {
    background-color: rgba(255, 204, 0, 0.8);
}
.backgroundText4 {
    background-color: rgba(255, 204, 0, 1);
    color: rgba(102, 0, 153, 1);
    top: 40%;
    text-transform: uppercase;
}

.stage5 {
    background-color: rgba(255, 51, 102, 0.8);
}
.backgroundText5 {
    background-color: rgba(255, 51, 102, 1);
    color: rgba(0, 204, 255, 1);
    transform: rotate(15deg);
    font-size: 10em;
}

.stage6 {
    background-color: rgba(255, 0, 0, 0.8);
}
.backgroundText6 {
    background-color: rgba(255, 0, 0, 1);
    color: rgba(0, 51, 153, 1);
    text-transform: capitalize;
    font-size: 10em;
}

.stage7 {
    background-color: rgba(204, 0, 153, 0.8);
}
.backgroundText7 {
    background-color: rgba(204, 0, 153, 1);
    color: rgba(0, 204, 153, 1);
    top: 60%;
    font-size: 10em;
}

.stage8 {
    background-color: rgba(153, 51, 255, 0.8);
}
.backgroundText8 {
    background-color: rgba(153, 51, 255, 1);
    color: rgba(51, 204, 51, 1);
    text-transform: uppercase;
    text-align: center;
    transform: rotate(-30deg);
}

.stage9 {
    background-color: rgba(51, 204, 153, 0.8);
}
.backgroundText9 {
    background-color: rgba(51, 204, 153, 1);
    color: rgba(204, 0, 153, 1);
}

.stage10 {
    background-color: rgba(0, 51, 153, 0.8);
}
.backgroundText10 {
    background-color: rgba(0, 51, 153, 1);
    color: rgba(255, 0, 0, 1);
    top: 40%;
    text-transform: uppercase;
    text-align: center;
    transform: rotate(-30deg);
    font-size: 8em;
}
