.introdution {
    margin-top: 50px;
    text-align: left;
    display: inline-block;
    max-width: 400px;

    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;

}

.introdution a {
    color: #007bff;
    text-decoration: none;
}

.introdution a:hover {
    text-decoration: underline;
}