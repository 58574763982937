.PrepRoom .input-area {
    margin: 40px 0;
}

.PrepRoom .search-input {
    width: 70%;
    max-width: 600px;
    height: 50px;
    padding: 0 10px;
    font-size: 1.2rem;
    border: 4px solid #0b0b0b;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
}

.PrepRoom .search-input:focus {
    border-color: #4d90fe;
    box-shadow: 0 0 8px rgba(77, 144, 254, 0.3);
}

.PrepRoom .search-btn {
    display: inline-block;
    height: 50px;
    margin-top: 20px;
    padding: 10px 16px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    background-color: #4285f4;
    border: 4px solid black;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
}

.PrepRoom .search-btn:hover {
    background-color: #286ff0;
}

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    display: inline-block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.PrepRoom .history {
    max-width: 350px;
    display: inline-block;
    background-color: #f3f3f3;
    margin: 20px 0;
    word-wrap: break-word;
}

.PrepRoom .history ul {
    text-align: left;
    padding: 0 10px;
}

.PrepRoom .history li {
    cursor: pointer;
    color: gray;
    background-color:#dfdddd;
    margin: 4px 0;
    padding: 2px 10px;
}

.PrepRoom .history li:hover {
    background-color: gray;
    color: white;
}

.PrepRoom .loading-area {
    text-align: center;
    margin: 70px 0;
}